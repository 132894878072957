import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const Toolbox = ({ authenticated, selectedImages, handleDownload }) => {
  let toolboxClassName = selectedImages.length ? 'subscription' : 'inactive-subscription';

  return (
    <div className={cx('toolbox', { public: authenticated, [toolboxClassName]: true })}>
      <div className={cx('tool-btn download-all')} onClick={selectedImages.length ? handleDownload : undefined}>
        <FontAwesomeIcon icon={faDownload} />
        <p>DOWNLOAD SELECTED</p>
      </div>
    </div>
  );
};

export default Toolbox;
