import Generator from 'components/generator';
import React, { useState, useEffect } from 'react';
import './index.scss';
import Logo from 'assets/images/storybookai-logo.png';
import BackIcon from 'assets/images/arrow-left-black.png';

function preventRightClick(event) {
  event.preventDefault();
}

const Home = () => {
  const [showDashboardLink, setShowDashboardLink] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState('');
  useEffect(() => {
    const referrer = typeof document !== 'undefined' ? document.referrer : '';
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    if (referrer && referrer !== currentUrl) {
      const referrerOrigin = new URL(referrer).origin;
      setDashboardUrl(referrerOrigin + '/my-account');
      setShowDashboardLink(true);
    }

    document.addEventListener('contextmenu', preventRightClick);
    return () => {
      document.removeEventListener('contextmenu', preventRightClick);
    };
  }, []);

  const backToDashboard = () => {
    if(dashboardUrl) window.location.href = dashboardUrl;
  }

  return (
    <div className="home-container">
      { showDashboardLink ? (
      <div className="back-container" onClick={backToDashboard}>
        <img src={BackIcon} alt="Back"
        width={30}
        height={30}></img>
        <span>Dashboard</span>
      </div> ) : <></> }
      <div className="title">
        <img src={Logo} alt="Logo"></img>
        <h1 style={{ fontSize: '16px' }}>Transform your textual visions into captivating visual worlds</h1>
      </div>
      <Generator />
    </div>
  );
};

export default Home;
