import { useState, useEffect } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles, faXmark, faLock, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Viewer from 'components/viewer';
import Toolbox from 'components/toolbox';
import axios from 'axios';
import './index.scss';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import fetchImage from './img_cors.js';
import Spinner from 'components/spinner';
import Swal from 'sweetalert2';

const beforeGenerate = <div className="before-generate">AI Generated Image will appear here</div>;

const Generator = () => {
  const [imageError, setImageError] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [checkedState, setCheckedState] = useState({
    fantasy: false,
    anime: false,
    pencil: false,
    nouveau: false,
    watercolor: false,
    deco: false,
    acrylic: false,
  });
  const [isImgClicked, setIsImgClicked] = useState(false);

  const lockedStyles = ['watercolor', 'deco', 'acrylic'];
  const [thumb1Url, setThumb1Url] = useState(null);
  const [thumb2Url, setThumb2Url] = useState(null);
  const [thumb3Url, setThumb3Url] = useState(null);
  const [thumb4Url, setThumb4Url] = useState(null);
  const [thumb5Url, setThumb5Url] = useState(null);
  const [thumb6Url, setThumb6Url] = useState(null);
  const [flag, setFlag] = useState(false);
  const [generation, setGeneration] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [authenticated] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [thumbBlur, setThumbBlur] = useState([false, false, false, false, false, false]);
  const [isLegalAge, setIsLegalAge] = useState(false);

  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    if(thumb1Url && thumb2Url && thumb3Url
      && thumb4Url && thumb5Url && thumb6Url) {
        setIsGenerating(false);
    }
  }, [thumb1Url, thumb2Url, thumb3Url, thumb4Url, thumb5Url, thumb6Url]);

  useEffect(() => {
    let forUserInterval = setInterval(() => {
      if(typeof window.AIPRO_USER !== 'undefined') {
        if( (window.AIPRO_USER.auth_version === 'v1' && window.AIPRO_USER.subscription_type)
          || 
          (window.AIPRO_USER.subscription_type && window.AIPRO_USER.subscription_type.toString().toLowerCase() !== 'basic')
        ) setSubscription(true);
        setShowPage(true);
      clearInterval(forUserInterval);
      }
    }, 500);
  });

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedImages((prevSelectedImages) => [...prevSelectedImages, value]);
    } else {
      setSelectedImages((prevSelectedImages) => prevSelectedImages.filter((image) => image !== value));
    }
  };

  const handleDownload = () => {
    if (selectedImages.length === 0) {
      return;
    }
    let confirmAge = false;
    setLoading(true);
    const zip = new JSZip();
    const promises = selectedImages.map(async (image) => {
      const el = document.querySelector(`input[value="${image}"] + img`)
      if (!confirmAge
        && ((image === 'thumb1' && thumbBlur[0])
        || (image === 'thumb2' && thumbBlur[1])
        || (image === 'thumb3' && thumbBlur[2])
        || (image === 'thumb4' && thumbBlur[3])
        || (image === 'thumb5' && thumbBlur[4])
        || (image === 'thumb6' && thumbBlur[5])
      )) confirmAge = true;
      if(el) {
        const imageSrc = el.src;
        // console.log('Downloading image:', imageSrc);
        const blob = await fetchImage(imageSrc);
        // console.log('Image blob:', blob);
        zip.file(`${image.split('/').pop()}.png`, blob);
      }
    });
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        if(confirmAge) {
          Swal.fire({
            title: "Confirmation Required",
            html: "Please confirm that you are 18 years old or above.",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#16a34a"
          }).then((result) => {
            if (result.isConfirmed) {
              setIsLegalAge(true);
              saveAs(content, 'images.zip');
            }
          });
        } else {
          saveAs(content, 'images.zip');
        }
        setLoading(false);
      });
    });
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);

    if (e.key === 'Enter') {
      generateImage();
    }
  };

  const removeDescription = () => {
    setDescription('');
  };

  const clearImage = () => {
    setThumb1Url(null);
    setThumb2Url(null);
    setThumb3Url(null);
    setThumb4Url(null);
    setThumb5Url(null);
    setThumb6Url(null);
    setThumbBlur([false, false, false, false, false, false]);
    setSelectedImages([]);
  };

  const loadImage = (url, ctr) => {
    return new Promise((res, rej) => {
      const image = new Image();
      image.src = url[0];
      image.onload = () => {
        if(ctr === 0) {
          setThumb1Url(image.src);
        } else if(ctr === 1) {
          setThumb2Url(image.src);
        } else if(ctr === 2) {
          setThumb3Url(image.src);
        } else if(ctr === 3) {
          setThumb4Url(image.src);
        } else if(ctr === 4) {
          setThumb5Url(image.src);
        } else if(ctr === 5) {
          setThumb6Url(image.src);
        }
        res();
        setLoading(false);
        setImageError(false);
      };

      image.onerror = (e) => {
        console.warn('image was not loaded', e);
        rej();
        setImageError(true);
        setLoading(false);
      };
    });
  };

  const generateImage = async () => {
    setIsImgClicked(false);
    setImageError(false);
    try {
      setLoading(true);

      const { fantasy, anime, pencil, nouveau, watercolor, deco, acrylic } = checkedState;

      if (description.trim().length) {
        if( window.isLimitedUsage() ) return;
        setGeneration(true);
        setIsGenerating(true);
        clearImage();
        for( var i = 0; i < 6; i++) {
          axios.post(
            `api/generate`, {
              description,
              fantasy,
              anime,
              pencil,
              nouveau,
              watercolor,
              deco,
              acrylic,
              ctr: i
            }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
          ).then(function (res) {
            const response = res.data;
            setResponseStatus("success");
            let blur = thumbBlur;
            blur[response.ctr] = response.blur[0] ? true : false;
            setThumbBlur(blur);

            loadImage(response.output, response.ctr);
          }).catch(function (error) {

          });
        }
      } else {
        alert('Please type text description');
        setImageError(true);
        return;
      }
    } catch (error) {
      console.log(error);
      setImageError(true);
    } finally {
      setLoading(false);
    }
  };

  const upgradePopup = () => {
    window.btutil_modalRegisterUpgrade();
  };

  const handleToggle = (key) => {
    if (subscription || !lockedStyles.includes(key)) {
      setCheckedState((s) => ({ ...s, [key]: !s[key] }));
    } else {
      upgradePopup();
      return;
    }
  };

  const expandStyles = () => {
    setFlag(!flag);
  };

  const styleOrder = ["fantasy", "anime", "pencil", "nouveau", "watercolor", "deco", "acrylic"];

  return (
    <>
    { showPage ?
    <div className="generator-container container">
      <div className="generator-content row">
        <div className={`keyword-form col-md-12 ${isGenerating ? 'disabled' : ''}`}>
          <input disabled={isGenerating} placeholder="Describe image here" onChange={handleDescription} value={description} onKeyDown={handleDescription}/>
          <div className="utils-box">
            {description.length ? <FontAwesomeIcon icon={faXmark} className="xMark" onClick={!isGenerating ? removeDescription : undefined} /> : ''}
            <div className="generate-button" onClick={!isGenerating ? generateImage : undefined}>
              <FontAwesomeIcon icon={faWandMagicSparkles} />
              <p>GENERATE</p>
            </div>
          </div>
        </div>
        <div className="styles-container col-md-12">
          <div className="styles-container-title">ADD STYLE:</div>
          <div className={cx('style-items', { open: flag })}>
            {styleOrder.map((key) => (
              <div
                className={cx(key, { active: checkedState[key] }, { locked: lockedStyles.includes(key) && !subscription })}
                onClick={() => handleToggle(key)}
                key={key}
              >
                {key}
                {!subscription && lockedStyles.includes(key) && <FontAwesomeIcon icon={faLock} />}
              </div>
            ))}
          </div>
          <FontAwesomeIcon icon={faAngleDown} className="angleDown" onClick={expandStyles} />
        </div>
      </div>
      {generation ? (
        <div className="row">
          {loading ? (
            <div className="generated-images-viewer loader">
              <div className="c">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            </div>
          ) : (
            <>
              {imageError ? (
                <div className="generated-images-viewer col-md-12">
                  <div className="image_error">Failed to load image. Please try again in a bit!</div>
                </div>
              ) : (
                <>
                  <Viewer
                    thumb1={thumb1Url}
                    thumb2={thumb2Url}
                    thumb3={thumb3Url}
                    thumb4={thumb4Url}
                    thumb5={thumb5Url}
                    thumb6={thumb6Url}
                    thumbBlur={thumbBlur}
                    status={responseStatus}
                    authenticated={authenticated}
                    subscription={subscription}
                    handleCheckboxChange={handleCheckboxChange}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    isLegalAge={isLegalAge}
                    setIsLegalAge={setIsLegalAge}
                    isImgClicked={isImgClicked}
                    setIsImgClicked={setIsImgClicked}
                  >
                  </Viewer>
                  <div className="tool-box col-md-12">
                    <Toolbox
                      authenticated={authenticated}
                      subscription={subscription}
                      subscription_plan={subscription.subscription}
                      selectedImages={selectedImages}
                      setLoading={setLoading}
                      handleDownload={handleDownload}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="images-container row">{beforeGenerate}</div>
      )}

    </div> : <><Spinner></Spinner></> }
    </>
  );
};

export default Generator;