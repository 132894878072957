import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Home from 'layout/home';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [ btutilAssetUrl ] = useState(process.env.REACT_APP_BTUTIL_ASSET_URL);
  const [ ver ] = useState(new Date().getTime());
  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
          #modal-container .close{
            background: transparent;
            border: none;
            position: absolute;
            color: white;
            right: 10px;
            top: 0px;
            font-size: 35px;
          }
          `}
        </style>
        <script>{` const ver = new Date().getTime(); `}</script>
        <script type="text/javascript" src={btutilAssetUrl + ver} async></script>
        <script src="/script.js"></script>
      </Helmet>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
