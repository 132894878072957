import { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Thumbnail from 'assets/images/thumbnail.png';
import './index.scss';
import cx from 'classnames';

const breakPoints = [
  { width: 390, itemsToShow: 3, itemsToScroll: 4 },
];

const Item = styled.div`
  &.locked {
    position: relative;
    opacity: 0.5;
    background-color: #d9d9d9;
  }
`;

const Thumbnails = ({ thumb1, thumb2, thumb3, thumb4, thumb5, thumb6, thumbBlur, status, subscription, setMainUrl, selectedImages, handleCheckboxChange }) => {
  const [items, setItems] = useState([thumb1, thumb2, thumb3, thumb4, thumb5, thumb6]);

  useEffect(() => {
    setItems([thumb1, thumb2, thumb3, thumb4, thumb5, thumb6]);
  }, [status, thumb1, thumb2, thumb3, thumb4, thumb5, thumb6]);

  const upgradePopup = () => {
    window.btutil_modalRegisterUpgrade();
  };

  return (
    <div className="thumbnails mobiledisplay">
      <Carousel breakPoints={breakPoints}>
        {items.map((item, index) => (
          <Item
            key={index}
            onClick={ index >= 2 && !subscription ? upgradePopup: undefined }
            className={`thumbnail-item ${index < 2 || !item ? 'guest' : subscription ? 'subscribed' : 'locked'}`}
          >
            { item && !(index >= 2 && !subscription) ? <>
              <input type="checkbox"
                onChange={(e) => {
                  handleCheckboxChange(e);
                  setMainUrl(item);
                  const thumbRef = document.querySelector(`.thumb${index + 1}`);
                  if (thumbRef) thumbRef.click();
                }}
                value={`thumb${index + 1}`}
                checked={selectedImages.includes(`thumb${index + 1}`)}
              />
            </> : <></> }
            <img
              className={cx("thumbnail-items", { blur : thumbBlur[1 - 1] })}
              src={item ? item : Thumbnail}
              alt={`thumb${index}`}
            />
            {index >= 2 && !subscription && item && <FontAwesomeIcon icon={faLock} />}
          </Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Thumbnails;
