const fetchImage = async (url) => {

  try {
    // console.log('Fetching image:', url);
    const response = await fetch(process.env.REACT_APP_CORS_URL+`?url=${url}`);
    // console.log('Response status:', response.status);
    // console.log('Response content type:', response.headers.get('Content-Type'));
    const blob = await response.blob();
    return blob;
  } catch (error) {
    // console.error('Error fetching image:', error);
  }
};

export default fetchImage;